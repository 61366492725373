import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import Layout from "gatsby-theme-stack/src/integration-layout";
export const _frontmatter = {
  "title": "Hubspot",
  "author": "admin",
  "date": "2019-01-01T00:00:00.000Z",
  "image": "img/hubspot.svg"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = Layout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h1>{`Hubspot`}</h1>
    <p>{`Automatically push your leads to Hubspot`}</p>
    <hr></hr>
    <p>{`This integration is a must-have for users wanting to use both Crisp and Hubspot CRM. It automatically sends contact added on Crisp to Hubspot so you don't have to manually sync users`}</p>
    <p>{`Benefits of using Crisp and Hubspot:`}</p>
    <ul>
      <li parentName="ul">{`Auto syncs contacts added in Crisp on Hubspot`}</li>
      <li parentName="ul">{`Get an extended customer profile on Hubspot and access Crisp conversations in a click.`}</li>
      <li parentName="ul">{`Get the best of the two world : CRM combined with deep customer support features`}</li>
    </ul>



    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      